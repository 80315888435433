const settings = {
    LibraryApiEndpoint: 'https://api.coverfy.com/api/data-library/',
    ApiEndpoint: 'https://api.prudenzia.es/api/v2/',
    localEndpoint: '',
    BugsnagKey: '9687f381022a39c156af183dc4d4ad31',
    darkMainColor: '#4c874f',
    mainColor: '#66BB6A',
    lightColor: '#FFFFFF',
    assets: {
      faviconUri: 'https://prudenzia-production-static.s3.eu-central-1.amazonaws.com/logos/imagotipo.svg',
      login_logo: 'https://prudenzia-production-static.s3.eu-central-1.amazonaws.com/logos/prudenzia.png',
    },
};
module.exports = settings;
